import React, { useEffect, useState } from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { TopScorerTable } from '../../../../core/components/building-blocks/topscorer-table/topscorer-table';
import { Tab, TabContext, TabPanel, Tabs } from '../../../../core/components/controls/tabs';
import { TopScorerDto } from '../../../../core/models/dtos/topscorer.dto';
import { ClientRoutes } from '../../../../core/models/enums/client-routes.enum';
import { TopScorerTypes } from '../../../../core/models/enums/topscorer.enum';
import { getTopScorerIndice } from '../../../../core/services/topscorer.service';
import { getTotalScore } from '../../../../core/utils/top-scorer.util';
import { IndiceDescription } from '../indice-description/indice-description';

export const IndiceTopScorer: React.FC = () => {
  const { symbol } = useParams();
  const navigate = useNavigate();
  const urlQuery = new URLSearchParams(location.search);

  const [tabValue, setTabValue] = useState(TopScorerTypes.DIV);
  const [topScorer, setTopScorer] = useState<TopScorerDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const loadIndiceData = async (): Promise<void> => {
      if (symbol) {
        const data = await getTopScorerIndice(symbol, tabValue);
        setTopScorer(data);
        setIsLoading(false);
      }
    };

    loadIndiceData();
  }, [symbol, tabValue]);

  /** Set tab from url query */
  useEffect(() => {
    const tabQuery = urlQuery.get('tab');
    if (tabQuery) {
      setTabValue(tabQuery as TopScorerTypes);
    }
  }, [urlQuery.get('tab')]);

  /** Handle change tab view */
  const changeTabView = (newValue: TopScorerTypes): void => {
    setTabValue(newValue);
    navigate(
      {
        pathname: `${ClientRoutes.INDICE}/${symbol}`,
        search: `?${createSearchParams({ tab: newValue })}`,
      },
      {
        replace: true,
      }
    );
  };

  return (
    <>
      <IndiceDescription symbol={`${symbol}`} />

      <TabContext value={tabValue}>
        <div className='grid md:grid-cols-2'>
          <h2 className='font-bold text-2xl'>{symbol} TopScorer</h2>
          <div className='self-end justify-self-end'>
            <Tabs onChange={changeTabView}>
              <Tab label='Dividenden' value={TopScorerTypes.DIV} />
              <Tab label='High-Growth-Investing' value={TopScorerTypes.HGI} />
              <Tab label='Levermann' value={TopScorerTypes.LEV} />
            </Tabs>
          </div>
        </div>

        <TabPanel value={TopScorerTypes.DIV}>
          <TopScorerTable topScorer={topScorer} totalScore={getTotalScore(TopScorerTypes.DIV)} isLoading={isLoading} />
        </TabPanel>
        <TabPanel value={TopScorerTypes.HGI}>
          <TopScorerTable topScorer={topScorer} totalScore={getTotalScore(TopScorerTypes.HGI)} isLoading={isLoading} />
        </TabPanel>
        <TabPanel value={TopScorerTypes.LEV}>
          <TopScorerTable topScorer={topScorer} totalScore={getTotalScore(TopScorerTypes.LEV)} isLoading={isLoading} />
        </TabPanel>
      </TabContext>
    </>
  );
};
