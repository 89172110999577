import React, { useEffect, useState } from 'react';
import { ClientRoutes } from '../../../core/models/enums/client-routes.enum';
import { IndiceWidget } from '../components/indice-widget/indice-widget';
import { IndiceDto } from '../models/dtos/indice.dto';
import { loadAllIndices } from '../services/indice.service';

export const WorldIndices: React.FC = () => {
  const [indices, setIndices] = useState<IndiceDto[]>([]);

  useEffect(() => {
    const loadIndices = async (): Promise<void> => {
      const indices: IndiceDto[] = await loadAllIndices();
      setIndices(indices);
    };

    loadIndices();
  }, []);

  return (
    <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4'>
      {indices.map((indice) => {
        return (
          <IndiceWidget
            key={indice.symbol}
            url={`${ClientRoutes.INDICE}/${indice.symbol}`}
            symbol={indice.symbol}
            name={indice.name}
            count={indice.countStocks}
          />
        );
      })}
    </div>
  );
};
