import classNames from 'classnames';
import React from 'react';

interface TableCellProps {
  children: React.ReactNode;
  colspan?: number;
  className?: string;
  /** Set width in pixel */
  width?: number;
  align?: 'center' | 'left' | 'right' | 'justify' | 'char';
}

export const TableCell: React.FC<TableCellProps> = (props: TableCellProps) => {
  return (
    <td className={classNames('px-6 py-3 text-sm', props.className)} colSpan={props.colspan} align={props.align} width={props.width}>
      {props.children}
    </td>
  );
};
