import React, { useEffect, useState } from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { TopScorerTable } from '../../../../../core/components/building-blocks/topscorer-table/topscorer-table';
import { Tab, TabContext, TabPanel, Tabs } from '../../../../../core/components/controls/tabs';
import { TopScorerDto } from '../../../../../core/models/dtos/topscorer.dto';
import { TopScorerTypes } from '../../../../../core/models/enums/topscorer.enum';
import { getSectorsTopScorer } from '../../../../../core/services/topscorer.service';
import { getTotalScore } from '../../../../../core/utils/top-scorer.util';

export const TopScorerSector: React.FC = () => {
  const { sector } = useParams();
  const navigate = useNavigate();
  const urlQuery = new URLSearchParams(location.search);

  const [tabValue, setTabValue] = useState(TopScorerTypes.DIV);
  const [topScorer, setTopScorer] = useState<TopScorerDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const loadSectorTopScorer = async (): Promise<void> => {
      if (sector) {
        const topScorerDtos: TopScorerDto[] = await getSectorsTopScorer(sector, tabValue);
        setTopScorer(topScorerDtos);
        setIsLoading(false);
      }
    };

    loadSectorTopScorer();
  }, [tabValue]);

  useEffect(() => {
    const tabQuery = urlQuery.get('tab');
    if (tabQuery) {
      setTabValue(tabQuery as TopScorerTypes);
    }
  }, [urlQuery.get('tab')]);

  const handleChange = (newValue: TopScorerTypes): void => {
    setTabValue(newValue);
    navigate(
      {
        search: `?${createSearchParams({ tab: newValue })}`,
      },
      {
        replace: true,
      }
    );
  };

  return (
    <TabContext value={tabValue}>
      <div className='md:flex md:flex-row justify-between'>
        <h2 className='font-bold text-2xl'>Topscorer {sector}</h2>

        <Tabs onChange={handleChange}>
          <Tab label='Dividenden' value={TopScorerTypes.DIV} />
          <Tab label='High-Growth-Investing' value={TopScorerTypes.HGI} />
          <Tab label='Levermann' value={TopScorerTypes.LEV} />
        </Tabs>
      </div>

      <TabPanel value={TopScorerTypes.DIV}>
        <TopScorerTable topScorer={topScorer} totalScore={getTotalScore(TopScorerTypes.DIV)} isLoading={isLoading} />
      </TabPanel>
      <TabPanel value={TopScorerTypes.HGI}>
        <TopScorerTable topScorer={topScorer} totalScore={getTotalScore(TopScorerTypes.HGI)} isLoading={isLoading} />
      </TabPanel>
      <TabPanel value={TopScorerTypes.LEV}>
        <TopScorerTable topScorer={topScorer} totalScore={getTotalScore(TopScorerTypes.LEV)} isLoading={isLoading} />
      </TabPanel>
    </TabContext>
  );
};
