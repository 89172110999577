import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import App from './app';
import { ClientRoutes } from './core/models/enums/client-routes.enum';
import { HomeModule } from './modules/home/home.module';
import { ImprintRoutes } from './modules/imprint/imprint.routes';
import { IndiceRoutes } from './modules/indice/indice.routes';
import { PortfolioModule } from './modules/portfolio/portfolio.module';
import { PrivacyPolicyRoutes } from './modules/privacy-policy/privacy-policy.routes';
import { ScreenerModule } from './modules/screener/screener.module';
import { StockRoutes } from './modules/stock/stock.routes';
import { TopScorerRoutes } from './modules/top-scorer/top-scorer.routes';
import { UserRoutes } from './modules/user/user.routes';

export const AppRoutes: React.FC = () => {
  const location = useLocation();

  /** Change tab title when route changes */
  useEffect(() => {
    /** Split URL to get the last part  */
    const lastUrlPart =
      location.pathname !== '/' ? location.pathname.split('/').splice(-1).pop() : 'Die besten Aktien finden und analysieren.';

    /** Set tab title */
    if (lastUrlPart) {
      const routeTitle = lastUrlPart;
      document.title = `Tradesyze | ${capitalizeFirstLetter(routeTitle)}`;
    }
  }, [location]);

  /** First letter of word is capitalized */
  function capitalizeFirstLetter(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  return (
    <Routes>
      <Route path='/' element={<App />}>
        <Route index element={<HomeModule />} />
        <Route path='*' element={<Navigate to='/' />} />
        <Route path={`${ClientRoutes.STOCK}/*`} element={<StockRoutes />} />
        <Route path={`${ClientRoutes.USER}/*`} element={<UserRoutes />} />
        <Route path={`${ClientRoutes.PORTFOLIO}`} element={<PortfolioModule />} />
        <Route path={`${ClientRoutes.SCREENER}/*`} element={<ScreenerModule />} />
        <Route path={`${ClientRoutes.INDICE}/*`} element={<IndiceRoutes />} />
        <Route path={`${ClientRoutes.TOPSCORER}/*`} element={<TopScorerRoutes />} />
        <Route path={`${ClientRoutes.PRIVACY_POLICY}`} element={<PrivacyPolicyRoutes />} />
        <Route path={`${ClientRoutes.IMPRINT}`} element={<ImprintRoutes />} />
      </Route>
    </Routes>
  );
};
