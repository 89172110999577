import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from '../../../../core/components/controls/card/card';
import { loadStatisticCountries } from '../../../../core/services/statistic.service';

export const TopScorerCountries: React.FC = () => {
  const [countries, setCountries] = useState<any[]>([]);

  useEffect(() => {
    const loadCountries = async (): Promise<void> => {
      const countryDtos = await loadStatisticCountries();
      setCountries(countryDtos);
    };

    loadCountries();
  }, []);

  return (
    <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4'>
      {countries.map((country) => {
        return (
          <Link key={country.name} to={country.name}>
            <Card hoverEffect={true}>
              <div className='indice-widget flex flex-col items-center gap-2'>
                <h3 className='text-lg font-bold text-slate-900 sm:text-xl text-center'>{country.name}</h3>
                <span>{country.count}</span>
              </div>
            </Card>
          </Link>
        );
      })}
    </div>
  );
};
