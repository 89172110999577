export enum ClientRoutes {
  /** User pages */
  USER = '/user',
  /** User login page */
  USER_LOGIN = '/user/login',
  /** User login sub route */
  LOGIN = '/login',
  /** User registration page */
  USER_REGISTRATION = '/user/registration',
  /** User registraion sub route */
  REGISTRATION = '/registration',
  USER_FORGOT_PASSWORD = '/user/new_password',
  /** Forgot password sub route */
  FORGOT_PASSWORD = '/new_password',
  /** Email notice page after registration */
  EMAIL_NOTICE = '/email-notice',
  /** User portfolio page */
  PORTFOLIO = '/portfolio',
  /** Indice pages */
  INDICE = '/indice',
  /** World indice page */
  INDICE_WORLD = '/indice/world',
  /** Screener pages */
  SCREENER = '/screener',
  /** Stock page */
  STOCK = '/stock',
  /** Datenschutz Page */
  PRIVACY_POLICY = 'privacy-policy',
  /** Impressum */
  IMPRINT = 'imprint',
  /** TopScorer pages */
  TOPSCORER = '/topscorer',
  /** TopScorer overview stocks */
  TOPSCORER_STOCKS = '/topscorer/stocks',
  /** TopScorer overview countries */
  TOPSCORER_COUNTRIES = '/topscorer/countries',
  /** TopScorer overview sectors */
  TOPSCORER_SECTORS = '/topscorer/sectors',
}
