import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../core/components/controls/button/button';
import { ClientRoutes } from '../../../../core/models/enums/client-routes.enum';
import { LocalStorageTypes } from '../../../../core/models/enums/local-storage.enum';
import { setItemLocalStorage } from '../../../../core/services/local-storage.service';
import { AuthorizeResponseDto } from '../../models/dtos/authorize-response.dto';
import { OAuth2AuthorizeDto } from '../../models/dtos/oauth2-authorize.dto';
import { oauth2Google } from '../../services/auth.service';

import { ReactComponent as GoogleIcon } from '../../../../../assets/svg/google-icon.svg';

export const GoogleLoginButton: React.FC = () => {
  const navigate = useNavigate();
  const redirectURI = `${window.location.origin}/user/login?oauth=google`;

  useEffect(() => {
    const urlSearchQuery = new URLSearchParams(window.location.search);
    const code = urlSearchQuery.get('code');
    const oauth = urlSearchQuery.get('oauth');

    if (code && oauth === 'google') {
      const signIn = async (): Promise<void> => {
        const oauthAuthorizeDto: OAuth2AuthorizeDto = {
          code: code,
          redirect_uri: redirectURI,
        };
        const response: AuthorizeResponseDto | undefined = await oauth2Google(oauthAuthorizeDto);

        if (response) {
          setItemLocalStorage(LocalStorageTypes.JWT_TOKEN, response.access_token);
          navigate(ClientRoutes.PORTFOLIO);
          // Refresh page, that menu items are displayed
          navigate(0);
        }
      };

      signIn();
    }
  }, [window.location.search]);

  const onClickSignIn = (): void => {
    const client_id = '121304155456-ntb44u4i6vkrcjb66g2mi602k9kugjtl.apps.googleusercontent.com';
    const googleOauth2Url = `https://accounts.google.com/o/oauth2/auth?scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email&response_type=code&access_type=offline&redirect_uri=${redirectURI}&client_id=${client_id}`;
    window.location.assign(googleOauth2Url);
  };

  return (
    <Button type='submit' onClick={() => onClickSignIn()} icon={<GoogleIcon />}>
      <span className='mx-2'>Google</span>
    </Button>
  );
};
