import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from '../../../../core/components/controls/card/card';
import { loadStatisticSectors } from '../../../../core/services/statistic.service';

export const TopScorerSectors: React.FC = () => {
  const [sectors, setSectors] = useState<any[]>([]);

  useEffect(() => {
    const loadCountries = async (): Promise<void> => {
      const sectorsDtos = await loadStatisticSectors();
      setSectors(sectorsDtos);
    };

    loadCountries();
  }, []);

  return (
    <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4'>
      {sectors.map((sector) => {
        return (
          <Link key={sector.name} to={sector.name}>
            <Card hoverEffect={true}>
              <div className='indice-widget flex flex-col items-center gap-2'>
                <h3 className='text-lg font-bold text-slate-900 sm:text-xl text-center'>{sector.name}</h3>
                <span>{sector.count}</span>
              </div>
            </Card>
          </Link>
        );
      })}
    </div>
  );
};
