import React from 'react';
import { SinglePageContainer } from '../../core/components/controls/single-page-container/single-page-container';

interface TopScorerModuleProps {
  children: React.ReactNode;
}

export const TopScorerModule: React.FC<TopScorerModuleProps> = (props: TopScorerModuleProps) => {
  return <SinglePageContainer>{props.children}</SinglePageContainer>;
};
