import { OverviewStatisticDto } from '../models/dtos/overview-statistic.dto';
import { mwApiInstance } from './axios.middleware';

export const loadOverviewStatistic = async (): Promise<OverviewStatisticDto> => {
  const response = await mwApiInstance.get<OverviewStatisticDto>(`/statistic/overview`);
  return response.data;
};

export const loadStatisticCountries = async (): Promise<any[]> => {
  const response = await mwApiInstance.get<any[]>(`/statistic/countries`);
  return response.data;
};

export const loadStatisticSectors = async (): Promise<any[]> => {
  const response = await mwApiInstance.get<any[]>(`/statistic/sectors`);
  return response.data;
};
