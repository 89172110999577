import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CurrentTopScorer } from '../home/components/current-topscorer/current-topscorer';
import { TopScorerCountry } from './components/topscorer-countries/components/topscorer-country/topscorer-country';
import { TopScorerCountries } from './components/topscorer-countries/topscorer-countries';
import { TopScorerSector } from './components/topscorer-sectors/components/topscorer-sector';
import { TopScorerSectors } from './components/topscorer-sectors/topscorer-sectors';
import { TopScorerModule } from './top-scorer.module';

export const TopScorerRoutes: React.FC = () => {
  return (
    <TopScorerModule>
      <Routes>
        <Route
          path='stocks'
          element={
            <div className='max-w-screen-xl mx-auto'>
              <CurrentTopScorer numberOfStocks={1000} />
            </div>
          }
        />
        <Route path='countries' element={<TopScorerCountries />} />
        <Route path='countries/:country' element={<TopScorerCountry />} />
        <Route path='sectors' element={<TopScorerSectors />} />
        <Route path='sectors/:sector' element={<TopScorerSector />} />
      </Routes>
    </TopScorerModule>
  );
};
